// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-admin-add-exercise-drawer-js": () => import("./../../../src/pages/admin/AddExerciseDrawer.js" /* webpackChunkName: "component---src-pages-admin-add-exercise-drawer-js" */),
  "component---src-pages-admin-edit-exercise-drawer-js": () => import("./../../../src/pages/admin/EditExerciseDrawer.js" /* webpackChunkName: "component---src-pages-admin-edit-exercise-drawer-js" */),
  "component---src-pages-admin-edit-question-drawer-js": () => import("./../../../src/pages/admin/EditQuestionDrawer.js" /* webpackChunkName: "component---src-pages-admin-edit-question-drawer-js" */),
  "component---src-pages-admin-egzersiz-otomasyon-js": () => import("./../../../src/pages/admin/egzersiz-otomasyon.js" /* webpackChunkName: "component---src-pages-admin-egzersiz-otomasyon-js" */),
  "component---src-pages-admin-egzersizler-js": () => import("./../../../src/pages/admin/egzersizler.js" /* webpackChunkName: "component---src-pages-admin-egzersizler-js" */),
  "component---src-pages-admin-konular-js": () => import("./../../../src/pages/admin/konular.js" /* webpackChunkName: "component---src-pages-admin-konular-js" */),
  "component---src-pages-admin-upgraded-uyeler-js": () => import("./../../../src/pages/admin/upgraded-uyeler.js" /* webpackChunkName: "component---src-pages-admin-upgraded-uyeler-js" */),
  "component---src-pages-auth-activation-code-js": () => import("./../../../src/pages/auth/activation-code.js" /* webpackChunkName: "component---src-pages-auth-activation-code-js" */),
  "component---src-pages-auth-login-js": () => import("./../../../src/pages/auth/login.js" /* webpackChunkName: "component---src-pages-auth-login-js" */),
  "component---src-pages-auth-register-js": () => import("./../../../src/pages/auth/register.js" /* webpackChunkName: "component---src-pages-auth-register-js" */),
  "component---src-pages-egzersiz-js": () => import("./../../../src/pages/egzersiz.js" /* webpackChunkName: "component---src-pages-egzersiz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */)
}

